<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    getTarget: Fandom.getTarget
  }
};
</script>

<template>
  <footer v-if="!content.hide_footer" class="bg-warm-white" footer-component>
    <div class="container-fluid p-3 p-lg-5">
      <div class="row">
        <div class="col-12 col-md-auto mb-4 pr-md-5" v-if="content.logo">
          <div class="logo bg-contain-center" :style="{'background-image': `url('${content.logo.url}')`}"></div>
        </div>
        <div class="col-12 col-md-auto mb-4 px-md-5" v-for="(column, index) in content.columns" :key="index">
          <p class="text-uppercase font-weight-bold">{{column.title}}</p>
          <a
            class="nav-link p-0"
            v-for="(item, linkIndex) in column.links"
            :href="item.link.url"
            :key="`footer-nav-link-${index}-${linkIndex}`"
            >
            {{item.link.title}}
          </a>
        </div>
        <div class="col-12 col-md-auto mb-4 px-md-5" v-if="content.socials">
          <p class="text-uppercase font-weight-bold">{{ft("globals.footer.follow_us")}}</p>
          <div class="d-flex align-items-center">
            <a
              class="social-url"
              v-for="(social, index) in content.socials"
              :key="`social-${index}`"
              :class="{'ml-3 ml-lg-2': index > 0}"
              :href="social.link.url"
              :title="social.link.title"
              :target="getTarget(social.link)"
              >
              <img class="social-logo" :src="social.image.url" >
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" v-if="content.description" v-html="content.description">
        </div>
        <div class="col-12">
          <small>
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
$logo-size: 7.5rem;
$social-logo-size: 2rem;

.logo {
  width: $logo-size * 1.1;
  height: $logo-size;
}

.social-url {
  transition: filter .25s;

  @include hover {
    filter: invert(83%) sepia(12%) saturate(187%) hue-rotate(359deg) brightness(93%) contrast(89%);
  }
}

.social-logo {
  width: $social-logo-size;
  height: $social-logo-size;

  @include media-breakpoint-up(lg) {
    width: $social-logo-size + 0.5;
    height: $social-logo-size + 0.5;
  }
}

.nav-link {
  color: $black;

  @include hover {
    color: $link-hover-color;
  }
}
</style>
