<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="position-relative" page-blank-component>
    <div v-if="content.breadcrumb">
      <breadcrumb/>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
    
    <div class="container overflow-hidden my-4" v-if="intent">
      <div class="row justify-content-center themed-item">
        <div class="col-12 col-sm-8 text-left">
          <h2 class="h1">{{ intent.message }}</h2>
          <p class="pt-3">{{ intent.description }}</p>
          <div class="align-btn-group my-4">
            <a :href="applyContextToUrl('/')" class="btn shadow-none my-2 btn-warm-white">{{ ft('ecommerce.return_home') }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container my-4">
      <div v-if="isUserRegistered" class="row justify-content-center">
        <div class="col-12 col-sm-8">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Prodotto</th>
                <th scope="col">Totale</th>
                <th scope="col">Data</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="rows.length === 0">
                <th colspan="4" scope="row">
                  <div class="w-100 d-flex justify-content-center">
                    <i v-if="isLoading" class="fa-spin fal fa-circle-notch"></i>
                    <span v-else>{{ ft("globals.no_content") }}</span>
                  </div>
                </th>
              </tr>
              <tr v-for="(row, index) in rows" :key="index">
                <td class="status">
                  <i v-if="row.status=='succeeded'" class="fas fa-check-circle"></i>
                  <i v-else-if="row.status=='requires_payment_method'" class="fas fa-times-circle"></i>
                  <i v-else-if="['processing','pending'].includes(row.status)" class="fas fa-clock"></i>
                </td>
                <td class="font-weight-bold text-capitalize">{{ row.name }}</td>
                <td>{{ row.amount }}</td>
                <td>{{ row.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import moment from 'moment';
import { get } from 'lodash';
import Breadcrumb from 'breadcrumb';

moment.locale('it');

export default {
  mixins: [fandomBasicMixin],
  components: {Breadcrumb},
  data: function () {
    return {
      paymentsData: [],
      messageText: "",
      intent: null,
      isLoading: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.checkStatus();
      if (this.isUserRegistered) {
        this.isLoading = true;
        Fandom.ajax({
          url: "/api/v5/ecommerce/payments",
          method: "GET",
          success: (data) => {
            this.paymentsData = data;
            this.isLoading = false;
          }
        });
      }
    },
    checkStatus() {
      const paymentId = new URLSearchParams(window.location.search).get("payment_uuid");
      if (paymentId) {
        Fandom.ajax({
          url: `/api/v5/ecommerce/payment/${paymentId}`,
          method: "GET",
          success: (data) => {
            this.intent = this.formatIntent(data);
          }
        });
      }
    },
    formatIntent(intent) {
      let message, statusIconClass, description;
      switch (intent.status) {
        case "succeeded":
          message = this.ft(`ecommerce.status.${intent.status}`);
          description = this.ft(`ecommerce.description.${intent.status}`);
          statusIconClass = "fa-check-circle";
          break;
        case "processing":
        case "pending":
          message = this.ft(`ecommerce.status.${intent.status}`);
          description = this.ft(`ecommerce.description.${intent.status}`);
          statusIconClass = "fa-clock";
          break;
        case "requires_payment_method":
          message = this.ft(`ecommerce.status.${intent.status}`);
          description = this.ft(`ecommerce.description.${intent.status}`);
          statusIconClass = "fa-times-circle";
          break;
        case "canceled":
          message = this.ft(`ecommerce.status.${intent.status}`);
          description = this.ft(`ecommerce.description.${intent.status}`);
          statusIconClass = "fa-exclamation-circle";
          break;
        default:
          message = this.ft(`ecommerce.status.general`);
          description = this.ft(`ecommerce.description.general`);
          statusIconClass = "fa-exclamation-circle";
          break;
      }

      return {
        name: get(intent, "content_cache_name", ""),
        status: intent.status,
        statusIconClass,
        message,
        description,
        amount: this.$n(intent.amount_unit/100, 'currency', 'it'),
        date: moment(intent.updated_at).format('DD MMM YYYY, HH:mm')
      };
    }
  },
  computed: {
    isUserRegistered: Fandom.isUserRegistered,
    rows() {
      if (typeof this.paymentsData === 'undefined') {
        return [];
      }

      return this.paymentsData.map(this.formatIntent)
    }
  }
};
</script>

<style lang="scss">
.fa-check-circle {
  color: #2FB96C;
}

.fa-clock {
  color: #5577ED;
}

.fa-times-circle {
  color: #E54661;
}
</style>
