<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  computed: {
    columnClasses() {
      const size = get(this, ["content", "width"]);
      return [`col-md-${size}`];
    },
    containerClasses() {
      return this.propagateBackground ? "themed-item" : "";
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : ""
      };
    },
    contentClasses() {
      return this.propagateBackground ? "" : "themed-item";
    },
    contentStyles() {
      return {
        "background-image": this.propagateBackground ? "" : this.backgroundImage
      };
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="theme" list-keypoints-component>
    <div class="w-100 bg-cover-center" :class="[containerClasses, paddingY]" :style="containerStyles">
      <div class="container">
        <div class="row row-cols-1 row-cols-lg-3 bg-cover-center" :class="contentClasses" :style="contentStyles">
          <template v-for="(item, index) in content.items">
            <div class="col mb-4" :key="`desc-${index}`">
              <div class="card h-100 flex-column item text-center align-items-center">
                <img :src="item.icon.url" v-if="item.icon" class="icon mt-3"/>
                <div class="card-body">
                  <div class="h3 card-title text-uppercase align-items-center" v-if="item.title">
                    <span>{{item.title}}</span>
                  </div>
                  <div class="card-text mt-3" v-html="item.description" v-if="item.description"></div>
                  <a :href="item.button.url" :target="getTarget(item.button)" v-if="item.button" :class="item.button_class || content.button_class || 'btn-primary'" class="btn btn-block my-2">
                    {{item.button.title}}
                  </a>
                </div>
                <div class="card-footer w-100 bg-warm-orange" v-if="item.footer_text">
                  {{ item.footer_text }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[list-keypoints-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .col {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card {
    border: 2px solid var(--theme-color);
    border-radius: .5rem;

    &-title {
      display: flex;
      justify-content: center;
      flex: 1;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;

      .btn-block {
        width: 70%;
      }
    }

    &-text {
      flex: 1;
    }

    &-footer {
      border-top: 0;

      &:last-child {
        border-radius: 0 0 calc(.5rem - 2px) calc(.5rem - 2px);
      }
    }
  }

  .icon {
    width: 6rem;
    height: auto;
  }
}
</style>
