import { render, staticRenderFns } from "./page-on-demand-event.vue?vue&type=template&id=555b8b10"
import script from "./page-on-demand-event.vue?vue&type=script&lang=js"
export * from "./page-on-demand-event.vue?vue&type=script&lang=js"
import style0 from "./page-on-demand-event.vue?vue&type=style&index=0&id=555b8b10&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports