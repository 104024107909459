<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return { };
  },
  props: {
    eventbrite: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getTextField(field, optField) {
      if (optField && this.content[optField]) {
        return this.content[optField];
      }
      return get(this.content, [field, "text"], this.content[field]);
    },
    getUrlField(field) {
      return get(this.content, [field,"url"], this.content[field]);
    }
  },
  computed: {
    date() {
      // If the date is coming from extra fields then it is a string
      let date = this.content.start;
      if (date instanceof Object) {
        // If the date is coming from eventbrite event data then it is an object with value in "utc"
        date = date.utc;
      }
      return new Date(date).toLocaleDateString([], {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
    },
    title() {
      return this.getTextField("name", "title");
    },
    description() {
      return this.getTextField("summary");
    },
    imageUrl() {
      return this.getUrlField("logo");
    },
    url() {
      return this.getUrlField("url");
    },
    easyadminLink() {
      return this.eventbrite ? `https://www.eventbrite.com/myevent?eid=${this.content.id}` : this.easyadminId;
    },
    expired() {
      return this.content.valid_to < new Date().toISOString();
    },
    bookButtonText() {
      const title = get(this.content, ["url", "title"], null);
      return title || (this.eventbrite ? this.ft('globals.book') : this.ft('globals.details'));
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminLink" v-if="isContentVisibleToUser()" :class="contentType" class="position-absolute-center d-flex flex-column tile p-3" tile-eventbrite-component>
    <div class="ar-2by1 bg-cover-center w-100" :style="{backgroundImage: 'url(' + imageUrl + ')'}"></div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <div class="small">{{date}}</div>
      <div class="sold-out small px-2 text-uppercase pt-1" v-if="(eventbrite && (content.ticket_availability || {}).is_sold_out) || content.sold_out">{{ft('globals.sold_out')}}</div>
    </div>
    <div class="flex-grow-1 d-flex flex-column justify-content-between">
      <div>
        <div class="title h5 mb-3">{{title}}</div>
        <div class="description">{{description}}</div>
      </div>
      <div class="d-flex justify-content-between align-items-end mt-3">
        <!-- <button
          :class="{'disabled': (content.ticket_availability || {}).is_sold_out}"
          :disabled="(content.ticket_availability || {}).is_sold_out"
          v-if="eventbrite"
          class="btn px-4 py-2 btn-warm-white"
          :id="`eventbrite-widget-modal-trigger-${content.id}`"
          type="button"
        >{{ft('globals.book')}}</button>
        <a :class="[{'btn btn-warm-white px-4 py-2': !eventbrite}]" :href="url" target="_blank">{{ft('globals.details')}}</a> -->
        <a :href="url" class="btn btn-warm-white px-4 py-2" target="_blank">{{ bookButtonText }}</a>
      </div>
    </div>
    <div class="position-absolute-center expired-layer" v-if="expired"></div>
  </div>
</template>

<style lang="scss" scoped>
[tile-eventbrite-component] {
  background-color: #eee;

  .sold-out {
    background-color: $danger;
    color: #fff;
  }

  .title, .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .title {
    -webkit-line-clamp: 2;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .expired-layer {
    background-color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
  }
}
</style>
