<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  computed: {
    columnClasses() {
      const size = get(this, ["content", "width"], 12);
      return [`col-md-${size}`];
    },
    containerClasses() {
      return this.propagateBackground ? "themed-item" : "";
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : ""
      };
    },
    contentClasses() {
      return this.propagateBackground ? "" : "themed-item";
    },
    contentStyles() {
      return {
        "background-image": this.propagateBackground ? "" : this.backgroundImage
      };
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="theme" list-keypoints-component>
    <div class="w-100 bg-cover-center" :class="[containerClasses, paddingY]" :style="containerStyles">
      <div class="container">
        <div class="row justify-content-center bg-cover-center" :class="contentClasses" :style="contentStyles">
          <div class="col-12 d-flex justify-content-around flex-wrap" :class="columnClasses">
            <template v-for="(item, index) in content.items">
              <div class="d-flex flex-column item text-center align-items-center py-3 py-md-0" :key="`desc-${index}`">
                <img :src="item.icon.url" v-if="item.icon" class="icon mb-3"/>
                <div class="h3" v-if="item.title">
                  {{item.title}}
                </div>
                <div v-html="item.description" v-if="item.description"></div>
                <a :href="item.button.url" :target="getTarget(item.button)" v-if="item.button" :class="item.button_class || content.button_class || 'btn-primary'" class="btn mt-3">
                  {{item.button.title}}
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[list-keypoints-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .icon {
    width: 4rem;
    height: auto;
  }

  .item {
    max-width: 20rem;
  }
}
</style>
