<script>
import { get, debounce } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return { 
      scrolled: false
    };
  },
  mounted() {
    this.setScrollListener();
  },
  methods: {
    setScrollListener() {
      const scrollListenerHandler = () => debounce(() => {
        this.scrolled = window.scrollY !== 0;
      });
      Vue.nextTick(() => {
        $(window).on('scroll', scrollListenerHandler());
      });
    },
  },
  computed: {
    isFixed() {
      const sticky = this.pageModel.name_to_content[this.pageModel.main_content_name].sticky;
      return !sticky;
    },
    isMobile() {
      return globalState.viewport.xs || globalState.viewport.sm;
    },
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
    username() {
      const user = globalState.pageModel.user;
      return `${user.first_name} ${user.last_name.charAt(0)}.`;
    },
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    },
    navbarClasses() {
      return {
        "bg-transparent text-white": (!this.scrolled && this.isFixed),
        "bg-white text-black": this.scrolled,
        "fixed-top": this.isFixed,
        "sticky-top bg-white text-black": !this.isFixed
      };
    }
  }
};
</script>

<template>
  <nav class="navbar navbar-expand-lg justify-content-between px-3 px-md-5" :class="navbarClasses" navbar-component>
    <a class="navbar-brand d-flex align-items-center" :href="applyContextToUrl('/')" v-if="content.logo">
      <h1 class="mb-0">
        <img :src="content.logo.url" class="navbar-logo" :alt="logoAlt"/>
      </h1>
    </a>
    <div class="d-flex align-items-md-center flex-column flex-md-row align-items-end" v-if="!content.hide_registration">
      <div class="nav-item px-2 position-relative">
        <div v-if="isUserRegistered" class="text-right font-weight-bold">
          {{username}}<br>
          <a class="nav-item-url" :href="applyContextToUrl('/users/sign_out')">
            <small>{{ft('globals.navbar.sign_out')}}</small>
          </a>
        </div>
        <a v-else class="nav-item-url font-weight-bold" :href="applyContextToUrl('/users/sign_in')">
          {{ft('globals.navbar.sign_in')}}
        </a>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
$logo-height: 2.575rem;
$header-height: 4rem;

[navbar-component] {
  min-height: $header-height;
  transition: background-color $animation-time-fast linear;
}

.navbar-brand {
  min-height: calc(#{$header-height} - 1rem);
}

.nav-item {
  .nav-item-url {
    color: $white;
    text-decoration: none;
  }

  @include hover {
    .nav-item-url {
      color: $link-color;
    }
  }

  .bg-white & {
    .nav-item-url {
      color: $black;
    }

    @include hover {
      .nav-item-url {
        color: $link-hover-color;
      }
    }
  }
}

.navbar-logo {
  height: $logo-height;
  transition: filter .25s;

  @include media-breakpoint-up(lg) {
    height: $logo-height + 1;
  }

  .bg-white & {
    filter: grayscale(100%) brightness(0);
  }
}
</style>
