<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { merge, concat } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      events: []
    };
  },  
  mounted() {
    this.loadChildrenEvents(this.content.children);
  },
  watch: {
    'content.children'(newVal, oldVal) {
      if (newVal.length > 0) {
        console.log(newVal.filter(v => !oldVal.includes(v)));
        this.loadChildrenEvents(newVal.filter(v => !oldVal.includes(v)));
      }
    }
  },
  computed: {
    childrenToAdd() {
      return this.content.children.filter(c => !this.events.map(e => e.name).includes(c)).map(c => this.getContent(c));
    },
    children() {
      const events = this.events;
      return concat(events, this.childrenToAdd);
    } 
  },
  methods: {
    loadChildrenEvents(contentNames) {
      const eventbriteIds = (contentNames || []).map(child => this.getContent(child).eventbrite_id);
      this.getEvents(eventbriteIds);
    },
    getEvents(eventIds) {
      const params = {}
      const url = "/api/v5/eventbrite/events";
      if (!!eventIds && eventIds.length > 0) params["event_ids"] = eventIds;
      Fandom.ajax({
        url: `${url}?${(new URLSearchParams(params)).toString()}`,
        method: "GET",
        success: (response) => {
          const childrenToAdd = this.content.children.filter(c => !this.events.map(e => e.name).includes(c));
          const eventsToInitialize = response.events.filter(e => !this.events.map(e => e.id).includes(e.id));
          childrenToAdd.forEach(c => {
            let { eventbrite_id, ...content } = Fandom.deepClone(this.getContent(c));
            if (eventbrite_id) {
              const eventBriteInfo = response.events.find(e => e.id == eventbrite_id);
              if (eventBriteInfo) {
                // Property "name" from eventbrite cannot ovverride the "name" from content
                const { name, ...eventBriteInfoToMerge } = eventBriteInfo;
                content.title = name.text;
                content.eventbrite = true;
                content = merge(content, eventBriteInfoToMerge);
              }
            }
            Vue.set(this.events, this.events.length, content);
          });
          Vue.nextTick(() => {
            this.initCheckouts(eventsToInitialize);
          });
        },
        error: (error) => {
          console.error(error);
        }
      });
    },
    initCheckouts(events) {
      events.forEach(event => {
        const callback = () => {
          console.log(`Ordine completato per l'evento${event.id}`);
        };
        window.EBWidgets.createWidget({
          widgetType: 'checkout',
          eventId: event.id,
          modal: true,
          modalTriggerElementId: `eventbrite-widget-modal-trigger-${event.id}`,
          onOrderComplete: callback
        });
      })
    },
  }
};
</script>

<template>
  <grid-fixed
    :content="content"
    :opt-container-content="containerContent"
    :items="children"
    grid-eventbrite-component
  >
    <template #default="tileData">
      <transition enter-active-class="animated fadeIn faster">
        <tile-eventbrite
          :key="tileData.child.name"
          v-if="!tileData.child.eventbrite_id"
          :content="tileData.child"
          :eventbrite="tileData.child.eventbrite"
          btn-class="btn-warm-white"
        ></tile-eventbrite>
      </transition>
      <transition enter-leave-class="animated fadeOut">
        <div
          :key="tileData.child.name"
          class="position-absolute-center placeholder"
          v-if="tileData.child.eventbrite_id"
        ></div>
      </transition>
    </template>
  </grid-fixed>
</template>

<style lang="scss">
[grid-eventbrite-component] {
  [tile-eventbrite-component] {
    background-color: #fff !important;

    .description {
      font-size: .825rem;
      line-height: 1.125rem;
    }
  }

  .placeholder {
    background-color: #fff;
  }
}
</style>