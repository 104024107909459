<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="position-relative" page-payment-component>
    <div v-if="content.breadcrumb">
      <breadcrumb/>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
    <stripe-two-col :content="content" :opt-container-content="optContainerContent">
      <template #stripe-two-col-custom-media-column>
        <component :is="mediaComponent" :content="paymentContent" :opt-container-content="optContainerContent"></component>
      </template>
    </stripe-two-col>
  </div>
</template>


<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  computed: {
    mediaComponent() {
      return this.content.media_column.component;
    },
    paymentContent() {
      return globalState.pageModel.name_to_content[this.content.media_column.content];
    }
  }
};
</script>