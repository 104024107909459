<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { PaymentForm } from 'payment';
import Breadcrumb from 'breadcrumb';

export default {
  components: { PaymentForm, Breadcrumb },
  mixins: [fandomBasicMixin]
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" class="position-relative" page-payment-component>
    <div v-if="content.breadcrumb">
      <breadcrumb/>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>

    <div class="container my-4">
      <div class="row justify-content-center">
        <div v-if="content.title" class="col-12 col-sm-8">
          <div class="h3">{{content.title}}</div>
        </div>
        <payment-form :content="content" :opt-container-content="containerContent"></payment-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>